import React from 'react';
import contact1 from "../../assets/Images/contact.png";
import "./Contact.css";
import { Link } from 'react-router-dom';

const Contact = () => {
  return (
    <div className='container'>
        <div className='contact-mian'>
            <div className='contact-sec1'>
                <h4>
                    Contact:
                </h4>
                <div className='contact-sec1-data'>
                    <p>
                        I&SBouw is doorgaans opzoek naar uitdagende projecten. Bent u opzoek naar een betrouwde en flexibele partner, 
                        neem dan vrijblijvend contact op!
                    </p>
                    <div className='contact-sec1-image'>
                        <img src={contact1} alt='contact-logo'/>
                    </div>
                </div>
            </div>
            <div className='contact-sec2'>
                <h3>
                    Waar vind u ons?
                </h3>
                <h6>
                    Adres:
                </h6>
                <Link to="">Weena 505 <br/>3013AL, Rotterdam</Link>
                <h6>
                    Telefoonnummmer:
                </h6>
                  <a href="tel:068 725 7911">068 725 7911</a>
                <h6>
                    E-mailadres:
                </h6>
                <a href="mailto:info@isbouw.nl">info@isbouw.nl</a>
                {/* <p>
                    8322 9469
                </p>
                <p>
                    0000 4941 4992
                </p> */}
            </div>
        </div>
    </div>
  )
}
export default Contact;
